import type { Updaters } from '~/adapter/indexedDB/types'
import type { OfflineDownloadInfoSchemaV3 } from '../../3/downloadInfo/downloadInfo'
import type { EmployeeSchemaV3 } from '../../3/employee/employee'
import type { FeatureFlagSchemaV3 } from '../../3/featureFlag/featureFlag'
import type {
  MultipleChoiceSetSchemaV3,
  TemplateMultipleChoiceSetRelationSchemaV3,
} from '../../3/multipleChoiceSet/multipleChoiceSet'
import type { NodeV3 } from '../../3/report/node/node'
import type { QuestionNodeV3 } from '../../3/report/node/questionNode/questionNode'
import type {
  ReportManualSchemaV3,
  ReportNodeSchemaV3,
  ReportSchemaV3,
} from '../../3/report/report'
import type { ScheduleItemSchemaV3 } from '../../3/schedule/scheduleItem'
import type {
  TemplateNodeSchemaV3,
  TemplateSchemaV3,
} from '../../3/template/template'
import type { TemplateMediaSchemaV3 } from '../../3/template/templateMedia'
import type { VersionSchemaV3 } from '../../3/version/version'
import type { OfflineDownloadInfoSchemaV4 } from '../downloadInfo/downloadInfo'
import type { EmployeeSchemaV4 } from '../employee/employee'
import type { FeatureFlagSchemaV4 } from '../featureFlag/featureFlag'
import type {
  MultipleChoiceSetSchemaV4,
  TemplateMultipleChoiceSetRelationSchemaV4,
} from '../multipleChoiceSet/multipleChoiceSet'
import type { NodeV4 } from '../report/node/node'
import type {
  HintMediaByFileUuidV4,
  HintV4,
} from '../report/node/questionNode/hint'
import type { QuestionNodeV4 } from '../report/node/questionNode/questionNode'
import type {
  ReportManualSchemaV4,
  ReportNodeSchemaV4,
  ReportSchemaV4,
} from '../report/report'
import type { ScheduleItemSchemaV4 } from '../schedule/scheduleItem'
import type { ManualMediaByFileUuidV4 } from '../template/manual'
import type {
  TemplateNodeSchemaV4,
  TemplateSchemaV4,
} from '../template/template'
import type { TemplateMediaSchemaV4 } from '../template/templateMedia'
import type { VersionSchemaV4 } from '../version/version'

const version = 4
type versionType = typeof version

export const migration: Pick<
  Updaters[versionType],
  'upgradeBefore' | 'migrations'
> = {
  upgradeBefore: async () => {},
  migrations: {
    versions: (prev: VersionSchemaV3): VersionSchemaV4 => {
      return {
        ...prev,
        // NOTICE: version テーブルのインクリメントを忘れないこと
        version,
      }
    },
    featureFlags: (prev: FeatureFlagSchemaV3): FeatureFlagSchemaV4 => {
      return prev
    },
    reports: (prev: ReportSchemaV3): ReportSchemaV4 => {
      return prev
    },
    reportNodes: (prev: ReportNodeSchemaV3): ReportNodeSchemaV4 => {
      const current = {
        ...prev,
        data: {
          ...prev.data,
          nodes: migrateNodes(prev.data.nodes),
        },
      }
      return current
    },
    reportManuals: (prev: ReportManualSchemaV3): ReportManualSchemaV4 => {
      const current = {
        ...prev,
        data: {
          manuals: prev.data.manuals.map(convertManual),
        },
      }
      return current
    },
    employees: (prev: EmployeeSchemaV3): EmployeeSchemaV4 => {
      return prev
    },
    offlineDownloadInfos: (
      prev: OfflineDownloadInfoSchemaV3,
    ): OfflineDownloadInfoSchemaV4 => {
      return prev
    },
    scheduleItems: (prev: ScheduleItemSchemaV3): ScheduleItemSchemaV4 => {
      return prev
    },
    templates: (prev: TemplateSchemaV3): TemplateSchemaV4 => {
      return prev
    },
    templateNodes: (prev: TemplateNodeSchemaV3): TemplateNodeSchemaV4 => {
      const current = {
        ...prev,
        data: {
          ...prev.data,
          nodes: migrateNodes(prev.data.nodes),
        },
      }
      return current
    },
    templateMedia: (prev: TemplateMediaSchemaV3): TemplateMediaSchemaV4 => {
      return prev
    },
    multipleChoiceSets: (
      prev: MultipleChoiceSetSchemaV3,
    ): MultipleChoiceSetSchemaV4 => {
      return prev
    },
    templateMultipleChoiceSetRelations: (
      prev: TemplateMultipleChoiceSetRelationSchemaV3,
    ): TemplateMultipleChoiceSetRelationSchemaV4 => {
      return prev
    },
  },
}

const convertManual = (
  manual: ReportManualSchemaV3['data']['manuals'][0],
): ReportManualSchemaV4['data']['manuals'][0] => {
  if (manual.type === 'remote') {
    return manual
  }
  // NOTE: Map では Safari で structuredClone できないため、Record に変換する
  // https://kaminashi.atlassian.net/browse/INE-1964
  const mediaByFileUuid: ManualMediaByFileUuidV4 = {}
  for (const [fileUuid, manualMedia] of manual.mediaByFileUuid) {
    mediaByFileUuid[fileUuid] = manualMedia
  }
  return {
    ...manual,
    mediaByFileUuid,
  }
}

const migrateNodes = (prevNodes: ReportNodeSchemaV3['data']['nodes']) => {
  const nodes: ReportNodeSchemaV4['data']['nodes'] = {}
  for (const node of Object.values(prevNodes)) {
    if (node) {
      nodes[node.id] = migrateNode(node)
    }
  }
  return nodes
}

const migrateNode = (prevNode: NodeV3): NodeV4 => {
  switch (prevNode.type) {
    case 'logic': {
      return prevNode
    }
    case 'question': {
      return migrateQuestionNode(prevNode)
    }
    case 'section': {
      return prevNode
    }
    case 'page': {
      return prevNode
    }
    default:
      return prevNode satisfies never
  }
}

type QuestionNodeSchemaByType<
  T extends QuestionNodeV3['questionType'],
  K extends QuestionNodeV3 = QuestionNodeV3,
> = K extends {
  questionType: T
}
  ? K
  : never

const migrateQuestionNode = <T extends QuestionNodeV3['questionType']>(
  prevNode: QuestionNodeSchemaByType<T>,
): QuestionNodeV4 => {
  const hints: HintV4[] = prevNode.question.hints.map(hint => {
    if (hint.type === 'remote') {
      return hint
    }
    // NOTE: Map では Safari で structuredClone できないため、Record に変換する
    // https://kaminashi.atlassian.net/browse/INE-1964
    const mediaByFileUuid: HintMediaByFileUuidV4 = {}
    for (const [fileUuid, hintMedia] of hint.mediaByFileUuid) {
      mediaByFileUuid[fileUuid] = hintMedia
    }
    return {
      ...hint,
      mediaByFileUuid,
    }
  })
  return {
    ...prevNode,
    question: {
      ...prevNode.question,
      hints,
    },
  }
}
