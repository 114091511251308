import * as Sentry from '@sentry/browser'
import type {
  ReportSchemaLatest,
  EmployeeSchemaLatest,
  ReportNodeSchemaLatest,
  ReportManualSchemaLatest,
  TemplateMediaSchemaLatest,
} from '~/adapter/indexedDB/types'
import { Image } from '~/domain/report/model/image/image'
import type { Node } from '~/domain/report/model/report/node/node'
import type { Hint } from '~/domain/report/model/report/node/questionNode/hint'
import type { QuestionType } from '~/domain/report/model/report/node/questionNode/question'
import type { QuestionNodeByType } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ReportMode } from '~/domain/report/model/report/reportMode'
import type { ToDefined } from '~/utils/types/defined'
import { convertEmployees } from './employee/convertEmployee'
import { ConvertToOfflineError } from './error'
import { convertInformationDocumentQuestionNode } from './questionNode/convertInformationDocument'
import { convertMemo } from './questionNode/convertMemo'
import { convertMultipleChoiceQuestionNode } from './questionNode/convertMultipleChoice'
import { convertResultImage } from './questionNode/convertResultImgae'
import { convertSignature } from './questionNode/convertSignature'
import type { State } from '../../../../../components/domain/EditReport/store/store'

type NodeSchema = ToDefined<ReportNodeSchemaLatest['data']['nodes'][0]>

type SaveOperationType = 'submit' | 'saveInProgress'

export const convertToOffline = async (
  state: State,
  companyId: number,
  saveOperationType: SaveOperationType,
  placeNodeId?: string,
): Promise<
  | {
      offlineReport: ReportSchemaLatest
      offlineReportNode: ReportNodeSchemaLatest
      offlineReportManual: ReportManualSchemaLatest
      offlineEmployee: EmployeeSchemaLatest
    }
  | Error
> => {
  try {
    if (!state.reportMode) {
      throw new ConvertToOfflineError('reportMode is required')
    }

    // offlineReport がある状態 = 必ずオフライン保存を行っているため、 UUID が付与されている
    const uuid = state.offlineReport
      ? state.offlineReport.uuid
      : ReportMode.getReportUuid(state.reportMode)

    const nodes: ReportNodeSchemaLatest['data']['nodes'] = {}
    for (const node of Object.values(state.report.nodes)) {
      if (node) {
        nodes[node.id] = await convertNode(node)
      }
    }

    const offlineReport: ReportSchemaLatest = {
      uuid,
      placeNodeId,
      companyId,
      data: {
        saveOperationType,
        reportMode: state.reportMode,
      },
    }

    const offlineReportNode: ReportNodeSchemaLatest = {
      uuid,
      companyId,
      data: {
        pageNodeIds: state.report.pageNodeIds,
        nodes,
      },
    }

    const offlineReportManual: ReportManualSchemaLatest = {
      uuid,
      placeNodeId,
      companyId,
      data: {
        manuals: state.report.manuals,
      },
    }

    const offlineEmployee = convertEmployees(
      state.report.employees,
      state.warnsInStartingReport.hasMoreEmployees,
      placeNodeId,
      companyId,
    )

    return {
      offlineReport,
      offlineReportNode,
      offlineReportManual,
      offlineEmployee,
    }
  } catch (e) {
    return processError(e)
  }
}

const processError = (e: unknown): Error => {
  if (e instanceof Error) {
    Sentry.captureException(e)
    return e
  } else {
    const error = new ConvertToOfflineError(
      'An unexpected error has occurred in converting store to request.',
      {
        unknownError: e,
      },
    )
    Sentry.captureException(error)
    return error
  }
}

const convertNode = async (node: Node): Promise<NodeSchema> => {
  switch (node.type) {
    case 'logic': {
      return node
    }
    case 'question': {
      switch (node.questionType) {
        case 'resultImage': {
          return convertResultImage(node)
        }
        case 'multipleChoice': {
          return convertMultipleChoiceQuestionNode(node)
        }
        case 'signature': {
          return convertSignature(node)
        }
        case 'informationDocument': {
          return convertInformationDocumentQuestionNode(node)
        }
        default: {
          return convertQuestionNodeWithMemo(node)
        }
      }
    }
    case 'section': {
      return node
    }
    case 'page': {
      return node
    }
    default:
      throw new ConvertToOfflineError(`Unexpected node type.`, { node })
  }
}

// questionNode の型に応じた処理を switch を使わずにうまく書く方法がないため、linter を抑止している
// 処理を煩雑にしないようにするため、この関数の責務は type に応じて呼び出す関数などを switch することだけに限定すること
// eslint-disable-next-line complexity
export const convertNodeWithMedia = async (
  node: Node,
): Promise<{
  media?: TemplateMediaSchemaLatest['media']['underlyingRemoteFile'][]
  node: NodeSchema
}> => {
  switch (node.type) {
    case 'logic': {
      return { node }
    }
    case 'question': {
      switch (node.questionType) {
        case 'resultImage': {
          return {
            media: extractMediaFromHints(node.question.hints),
            node: {
              ...node,
              answer: undefined,
              memo: await convertMemo(node.memo),
            },
          }
        }
        case 'multipleChoice': {
          const media = node.question.images.map(image => {
            const { uuid, url } = Image.getRemoteResourceInfo(image)
            return {
              uuid,
              url,
            }
          })
          return {
            media: media.concat(...extractMediaFromHints(node.question.hints)),
            node: await convertMultipleChoiceQuestionNode(node),
          }
        }
        case 'signature': {
          return {
            media: extractMediaFromHints(node.question.hints),
            node: {
              ...node,
              answer: undefined,
              memo: await convertMemo(node.memo),
            },
          }
        }
        case 'informationDocument': {
          const file =
            node.question.information &&
            Image.getRemoteResourceInfo(node.question.information)
          const media = file
            ? [
                {
                  uuid: file.uuid,
                  url: file.url,
                },
              ]
            : []
          return {
            media: media.concat(...extractMediaFromHints(node.question.hints)),
            node: await convertInformationDocumentQuestionNode(node),
          }
        }
        default: {
          return {
            media: extractMediaFromHints(node.question.hints),
            node: await convertQuestionNodeWithMemo(node),
          }
        }
      }
    }
    case 'section': {
      return { node }
    }
    case 'page': {
      return { node }
    }
    default:
      throw new ConvertToOfflineError(`Unexpected node type.`, { node })
  }
}

const convertQuestionNodeWithMemo = async <T extends QuestionType>(
  node: QuestionNodeByType<T>,
) => {
  return {
    ...node,
    memo: await convertMemo(node.memo),
  }
}

const extractMediaFromHints = (
  hints: Hint[],
): TemplateMediaSchemaLatest['media']['underlyingRemoteFile'][] =>
  hints.flatMap(hint => {
    if (hint.type === 'localRo') {
      throw new ConvertToOfflineError(`Unexpected hint type.`, { hint })
    }
    return hint.blocks
      .filter(block => block.type === 'image')
      .map(imageBlock => ({
        ...imageBlock.data.file,
      }))
  })
