import { ResponseNumberSubTypeEnum } from '@ulysses-inc/harami_api_client'
import type { NumberAnswer } from '~/domain/report/model/report/node/questionNode/numberQuestionNode/numberAnswer'
import type { NumberQuestion } from '~/domain/report/model/report/node/questionNode/numberQuestionNode/numberQuestion'
import type { NumberQuestionNode } from '~/domain/report/model/report/node/questionNode/numberQuestionNode/numberQuestionNode'
import type { NumberRule } from '~/domain/report/model/report/node/questionNode/numberQuestionNode/numberRule'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { isNullish } from '~/utils/isNullish'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'
import type { ReportNodeSchema } from '@ulysses-inc/harami_api_client'

/**
 * 数値質問の API Schema を Store のデータ型に変換する
 *
 * question.responseNumbers が存在する条件に注意が必要
 *
 * - ルール・小数点以下桁数の設定双方が存在しない -> question.responseNumbers が存在しない（場合がある）
 * - ルール・小数点以下桁数の設定いづれか存在する -> question.responseNumbers が存在する
 *   - ルールのみが存在する -> responseNumber.decimalPoint = { isActive: 0, value: 0 } のように与えられる場合がある
 *   - 小数点以下の桁数の設定のみが存在する -> minimum,maximum は undefined、subType は 0 で与えられる場合がある
 *   -（ subType = 0 は API Schema に違反している）
 *
 * @param context
 * @returns
 */
export const convertNumberQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: {
      node,
      questionNodeBase,
      questionBase,
      deviateProperty,
      recordedAt,
      noAnswer,
    },
  } = context
  const responseAnswer = question.responseAnswer

  const numberQuestion: NumberQuestion = {
    ...questionBase,
    ...deviateProperty,
    type: 'number',
    rule: convertToRule(node),
    decimalPoint: convertToDecimalPoint(node),
    scale: question.scale ?? '',
  }

  let numberAnswer: NumberAnswer | undefined = undefined
  if (responseAnswer?.numberValue?.numberValue !== undefined) {
    numberAnswer = {
      type: 'number',
      value: responseAnswer.numberValue.numberValue,
      isInvalid: responseAnswer.numberValue.isInvalid === 1,
      recordedAt,
    }
  }

  const questionNode: NumberQuestionNode = {
    ...questionNodeBase,
    questionType: 'number',
    question: numberQuestion,
    answer: noAnswer ?? numberAnswer,
    draftAnswer: undefined,
  }

  return questionNode
}

/**
 * 数値の rule の型を変換する。
 * 〜の間、以上、以下それぞれについて、必要なフィールドが定義されていることを保証する
 *
 * @param node
 * @returns
 */
const convertToRule = (node: ReportNodeSchema): NumberRule | undefined => {
  const responseNumber = extractResponseNumber(node)
  if (!responseNumber) {
    return undefined
  }
  const ruleType = responseNumber.subType

  switch (ruleType) {
    case ResponseNumberSubTypeEnum.BETWEEN: {
      const minimum = responseNumber.minimum
      if (isNullish(minimum)) {
        throw new ApiToModelError(`minimum is not defined in node.`, {
          node,
        })
      }
      const maximum = responseNumber.maximum
      if (isNullish(maximum)) {
        throw new ApiToModelError(`maximum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'between',
        minimum,
        maximum,
      }
    }
    case ResponseNumberSubTypeEnum.LESS_THAN: {
      const maximum = responseNumber.maximum
      if (isNullish(maximum)) {
        throw new ApiToModelError(`maximum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'le',
        maximum,
      }
    }
    case ResponseNumberSubTypeEnum.GREATER_THAN: {
      const minimum = responseNumber.minimum
      if (isNullish(minimum)) {
        throw new ApiToModelError(`minimum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'ge',
        minimum,
      }
    }
    default:
      return undefined
  }
}

const convertToDecimalPoint = (
  node: ReportNodeSchema,
): NumberQuestion['decimalPoint'] => {
  const responseNumber = extractResponseNumber(node)
  if (!responseNumber?.decimalPoint?.isActive) {
    return undefined
  }
  const value = responseNumber.decimalPoint.value
  if (isNullish(value)) {
    throw new ApiToModelError(`decimalPoint.value is not defined in node.`, {
      node,
    })
  }
  return { value }
}

const extractResponseNumber = (node: ReportNodeSchema) => {
  const responseNumber = node.question?.responseNumbers?.[0]
  return responseNumber
}
