// Figma Link: https://www.figma.com/file/3swIU7IP5FEamZ3VErR7hU/%F0%9F%8D%85-Web?type=design&node-id=1554-14574&mode=design&t=mrUUYb7o64zE8kR7-0

import { blue, gray } from './kdsColors'

const baseColor = gray[9]

/** 透過度100% */
export const colorTextBase = baseColor + 'FF'
/** 透過度88% */
export const colorText = baseColor + 'E0'
/** 透過度65% */
export const colorTextSecondary = baseColor + 'A6'
/** 透過度45% */
export const colorTextTertiary = baseColor + '73'
/** 透過度25% */
export const colorTextQuaternary = baseColor + '40'

export const colorTextDisabled = colorTextQuaternary

/* 有彩色系 */
export const colorPrimary = blue[6]
