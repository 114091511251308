import {
  ResponseFormulaTokenTypeEnum,
  ResponseFormulaSubTypeEnum,
  ResponseFormulaTokenOperatorTypeEnum,
} from '@ulysses-inc/harami_api_client'
import type { FormulaAnswer } from '~/domain/report/model/report/node/questionNode/formulaQuestionNode/formulaAnswer'
import type {
  FormulaDefToken,
  DefTokenType,
  OperatorType,
} from '~/domain/report/model/report/node/questionNode/formulaQuestionNode/formulaDefToken'
import type { FormulaQuestion } from '~/domain/report/model/report/node/questionNode/formulaQuestionNode/formulaQuestion'
import type { FormulaQuestionNode } from '~/domain/report/model/report/node/questionNode/formulaQuestionNode/formulaQuestionNode'
import type { FormulaRule } from '~/domain/report/model/report/node/questionNode/formulaQuestionNode/formulaRule'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { isNullish } from '~/utils/isNullish'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'
import type {
  ReportNodeSchema,
  ResponseFormulaToken,
} from '@ulysses-inc/harami_api_client'

export const convertFormulaQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { node, questionNodeBase, questionBase, deviateProperty, recordedAt },
  } = context

  const responseAnswer = question.responseAnswer

  const responseFormula = question.responseFormulas?.[0]
  if (responseFormula === undefined) {
    throw new ApiToModelError('Formula question has no response formula.', {
      node,
    })
  }
  const formulaQuestion: FormulaQuestion = {
    ...questionBase,
    ...deviateProperty,
    type: 'formula',
    formulaDefTokens: convertToDefTokens(context),
    rule: convertToRule(node),
    scale: question.scale ?? '',
    decimalPoint: convertDecimalPoint(node),
  }

  let formulaAnswer: FormulaAnswer | undefined = undefined
  if (responseAnswer?.formulaValue?.formulaValue !== undefined) {
    formulaAnswer = {
      type: 'formula',
      value: responseAnswer.formulaValue.formulaValue,
      isInvalid: responseAnswer.formulaValue.isInvalid === 1,
      recordedAt,
    }
  }

  const questionNode: FormulaQuestionNode = {
    ...questionNodeBase,
    questionType: 'formula',
    question: formulaQuestion,
    answer: formulaAnswer,
  }

  return questionNode
}

const convertDecimalPoint = (
  node: ReportNodeSchema,
): FormulaQuestion['decimalPoint'] => {
  // NOTE: 数式に対して小数点以下桁数のルールが一度も有効化されていない場合は decimalPoint のレコードが存在しない
  // また、実際の計算を行う Calculator クラス側においてデフォルト挙動を設定しているので、
  // アクティブではない時は設定されていないものと同様に扱って良い
  const responseFormula = extractResponseFormula(node)
  if (!responseFormula.decimalPoint?.isActive) {
    return undefined
  }
  const value = responseFormula.decimalPoint.value
  if (isNullish(value)) {
    throw new ApiToModelError(`decimalPoint.value is not defined in node.`, {
      node,
    })
  }
  return { value }
}

/**
 * 計算式の tokens の型を変換する。
 * tokens が以下を満たすことも保証する
 *
 * - 要素数は最低３つ以上で、奇数個であることを保証する
 * - 計算式の定義は、中間記法でなされているため、奇数番目が constant or question, 偶数番目が operator であることを保証する
 *
 * @param node
 * @returns
 */
const convertToDefTokens = (context: ConversionContext): FormulaDefToken[] => {
  const {
    wholeContext: { nodeDict },
    base: { node },
  } = context
  const responseFormula = extractResponseFormula(node)

  const origTokens = responseFormula.tokens

  if (isNullish(origTokens)) {
    throw new ApiToModelError('tokens is not defined in node', { node })
  }

  const siblingNodeIds = selectSiblingNodeIds(context)

  const siblingNodeByUuid: { [uuid: string]: ReportNodeSchema } = {}
  for (const siblingNodeId of siblingNodeIds) {
    const siblingNode = nodeDict[siblingNodeId]
    if (siblingNode?.uuid === undefined) {
      throw new ApiToModelError(
        `siblingNode mapped to siblingNodeId is not defined.`,
        { node, siblingNodeId },
      )
    }
    siblingNodeByUuid[siblingNode.uuid] = siblingNode
  }

  const defTokens = mapToDefTokens(node, origTokens, siblingNodeByUuid)
  const errInfo = validateTokens(defTokens)
  if (errInfo) {
    throw new ApiToModelError(errInfo.message, { node, ...errInfo.extra })
  }

  return defTokens
}

const selectSiblingNodeIds = (context: ConversionContext) => {
  const {
    wholeContext: {
      pageDict,
      nodeDict,
      parentPageIdByNodeId,
      parentNodeIdByNodeId,
    },
    base: { node },
  } = context

  const parentPageId = parentPageIdByNodeId.get(node.id)
  const parentNodeId = parentNodeIdByNodeId.get(node.id)

  if (parentPageId === undefined && parentNodeId === undefined) {
    throw new ApiToModelError(
      `Neither parentPageId nor parentNodeId is defined in node.`,
      { node, parentPageId, parentNodeId },
    )
  }

  let siblingNodeIds: number[] = []
  if (parentPageId) {
    const parentNode = pageDict[parentPageId]
    if (isNullish(parentNode)) {
      throw new ApiToModelError(
        `parentNode mapped to parentPageId is not defined.`,
        { node, parentPageId },
      )
    }
    siblingNodeIds = parentNode.nodes
  }
  if (parentNodeId) {
    const parentNode = nodeDict[parentNodeId]
    if (isNullish(parentNode)) {
      throw new ApiToModelError(
        `parentNode mapped to parentNodeId is not defined.`,
        { node, parentNodeId },
      )
    }
    siblingNodeIds = parentNode.nodes
  }

  return siblingNodeIds
}

const mapToDefTokens = (
  node: ReportNodeSchema,
  origTokens: ResponseFormulaToken[],
  siblingNodeByUuid: { [uuid: string]: ReportNodeSchema },
): FormulaDefToken[] => {
  return origTokens.map(token => {
    if (isNullish(token.type)) {
      throw new ApiToModelError(`type is not defined in tokens of node.`, {
        node,
        token,
      })
    }

    switch (token.type) {
      case ResponseFormulaTokenTypeEnum.CONSTANT: {
        const constant = token.constant
        if (isNullish(constant)) {
          throw new ApiToModelError(`constant is not defined in node.`, {
            node,
            token,
          })
        }
        return {
          type: 'constant',
          value: constant,
        }
      }
      case ResponseFormulaTokenTypeEnum.QUESTION: {
        const questionUuid = token.questionNodeUUID
        const questionNode = siblingNodeByUuid[questionUuid ?? '']
        if (isNullish(questionNode)) {
          throw new ApiToModelError(`questionNode is not defined in node.`, {
            node,
            token,
            questionUuid,
          })
        }
        return {
          type: 'question',
          questionNodeId: questionNode.id,
        }
      }
      case ResponseFormulaTokenTypeEnum.OPERATOR: {
        if (isNullish(token.operator)) {
          throw new ApiToModelError(
            `operator is not defined in tokens of node.`,
            { node, token },
          )
        }
        return {
          type: 'operator',
          operator: operatorMapping[token.operator],
        }
      }
    }
  })
}

type ValidateTokensReturnType = {
  message: string
  extra:
    | {
        tokenLength: number
      }
    | {
        tokenType: DefTokenType
        index: number
      }
}
/**
 * 計算式の tokens のバリデーション
 *
 * NOTE: 細かいパターンを convertFormulaQuestionNode のテストで網羅しようとすると非常に煩雑になるので、別関数に切り出してテストしている
 *
 * @param tokens
 * @param nodeInfo
 * @returns
 */
export const validateTokens = (
  tokens: { type: DefTokenType }[],
): ValidateTokensReturnType | undefined => {
  // 要素数は最低３つ以上で、奇数個であることを保証する
  if (tokens.length < 3 || tokens.length % 2 !== 1) {
    return {
      message: 'invalid tokens length',
      extra: {
        tokenLength: tokens.length,
      },
    }
  }

  // 計算式の定義は、中間記法でなされているため、奇数番目が constant or question, 偶数番目が operator であることを保証する
  for (let i = 0; i < tokens.length; i++) {
    if (i % 2 === 0) {
      if (tokens[i].type !== 'constant' && tokens[i].type !== 'question') {
        return {
          message: 'invalid token type',
          extra: {
            tokenType: tokens[i].type,
            index: i,
          },
        }
      }
    } else {
      if (tokens[i].type !== 'operator') {
        return {
          message: 'invalid token type',
          extra: {
            tokenType: tokens[i].type,
            index: i,
          },
        }
      }
    }
  }

  return undefined
}

/**
 * 計算式の rule の型を変換する。
 * 〜の間、以上、以下それぞれについて、必要なフィールドが定義されていることを保証する
 *
 * @param node
 * @returns
 */
const convertToRule = (node: ReportNodeSchema): FormulaRule | undefined => {
  const responseFormula = extractResponseFormula(node)
  const ruleType = responseFormula.subType

  switch (ruleType) {
    case ResponseFormulaSubTypeEnum.BETWEEN: {
      const minimum = responseFormula.minimum
      if (isNullish(minimum)) {
        throw new ApiToModelError(`minimum is not defined in node.`, {
          node,
        })
      }
      const maximum = responseFormula.maximum
      if (isNullish(maximum)) {
        throw new ApiToModelError(`maximum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'between',
        minimum,
        maximum,
      }
    }
    case ResponseFormulaSubTypeEnum.LESS_THAN: {
      const maximum = responseFormula.maximum
      if (isNullish(maximum)) {
        throw new ApiToModelError(`maximum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'le',
        maximum,
      }
    }
    case ResponseFormulaSubTypeEnum.GREATER_THAN: {
      const minimum = responseFormula.minimum
      if (isNullish(minimum)) {
        throw new ApiToModelError(`minimum is not defined in node.`, {
          node,
        })
      }
      return {
        type: 'ge',
        minimum,
      }
    }
    default:
      return undefined
  }
}

const operatorMapping = {
  [ResponseFormulaTokenOperatorTypeEnum.PLUS]: '+',
  [ResponseFormulaTokenOperatorTypeEnum.MINUS]: '-',
  [ResponseFormulaTokenOperatorTypeEnum.MULTI]: '*',
  [ResponseFormulaTokenOperatorTypeEnum.DIVIDED]: '/',
} satisfies Record<ResponseFormulaTokenOperatorTypeEnum, OperatorType>

const extractResponseFormula = (node: ReportNodeSchema) => {
  const responseFormula = node.question?.responseFormulas?.[0]
  if (isNullish(responseFormula)) {
    throw new ApiToModelError('responseFormula is not defined in node', {
      node,
    })
  }
  return responseFormula
}
