import type {
  AppRoleEnum,
  CompanyContractPlan,
  CompanyContractStatus,
  ImproveStatus,
  NewFeatureVersionEnum,
  PlaceNodeTypeEnum,
  ReportStatusEnum,
  ResponseSetExcelOutputSettingEnum,
  TemplateIconTypeEnum,
  TemplateLayoutTypeEnum,
} from '@ulysses-inc/harami_api_client'

// TODO: 必要なフィールドを整理する
// labels: ph.3

type Company = {
  id: number
  name: string
  phoneNumber?: string
  postalCode?: string
  address?: string
  contactMailAddress?: string
  userLimit: number
  startContractDate?: Date
  endContractDate?: Date
  contractStatus: CompanyContractStatus
  contractPlan: CompanyContractPlan
  wovnToken?: {
    token: string
  }
  wovnEnableUsers?: {
    user: User
  }[]
  users?: User[]
}

type Place = {
  id?: number
  name?: string
  placeNodeId?: number
}

type PlaceNode = {
  id: number
  uuid: string
  type: PlaceNodeTypeEnum
  isRootNode: number
  place?: Place
  placeGroup?: {
    id?: number
    name?: string
    placeNodeId?: number
  }
  parentNodes?: PlaceNode[]
  nodes?: PlaceNode[]
}

type User = {
  id: number
  uuid: string
  name: string
  nameKana: string
  email: string
  password: string
  role: {
    role?: AppRoleEnum
  }
  loginId: string
  userGroups?: {
    id: number
    name: string
    users?: User[]
  }[]
  placeNodes?: PlaceNode[]
  company: Company
  isCompleteTutorial: number
  announcedNewFeatureVersion: NewFeatureVersionEnum
  isEditExcelConversion: number
  createdAt?: string
}

type MultipleChoiceSet = {
  uuid?: string
  name?: string
  excelOutputOption?: ResponseSetExcelOutputSettingEnum
  responses?: {
    uuid?: string
    name?: string
    discriminationKey?: string
    categories?: {
      name?: string
    }[]
  }[]
}
type ApprovalFlow = {
  id?: number
  name?: string
  uuid?: string
  description?: string
  steps?: {
    approvers?: User[]
  }[]
}

type Icon = {
  iconType?: TemplateIconTypeEnum
}

export type HintInfo = {
  id?: string
  name: string
}

type TemplateData = {
  id?: number
  versionId?: number
  name: string
  multipleChoiceSets?: MultipleChoiceSet[]
  approvalFlows?: ApprovalFlow[]
  isShowScore?: boolean
  isEditable?: boolean
  isExcelConversion?: boolean
  isHideQuestionOptions?: boolean
  isAudit?: boolean
  isKeyboard?: boolean
  layoutType?: TemplateLayoutTypeEnum
  icons?: Icon[]
  // NOTE: TemplateData としてまとめて扱いたいプロパティを追加する
  hints: HintInfo[]
}

type IndicatedIssueLabel = {
  id?: number
  uuid?: string
  name?: string
  color?: string
}

type IndicatedIssue = {
  indicatedIssueGroupUUID?: string
  labels?: IndicatedIssueLabel[]
}

type IndicatedIssueGroup = {
  id?: number
  uuid?: string
  indicatedIssues?: IndicatedIssue[]
}

type UserGroup = {
  id: number
  name: string
  users?: User[]
}

type Improve = {
  id?: number
  uuid: string
  status?: ImproveStatus
  indicatedIssueGroups?: IndicatedIssueGroup[]
  auditUser?: User
  placeUserGroup?: UserGroup
}

type ReportQuestionsIndicatedIssueGroup = {
  reportUUID?: string
  reportNodeId?: number
  reportNodeUUID?: string
  indicatedIssueGroupUUID?: string
}

export type ReportData = {
  id?: number
  uuid: string
  name?: string
  reportDate?: Date
  status?: {
    status?: ReportStatusEnum
  }
  schedules?: {
    id?: number
  }[]
  assignee?: User
  templateId?: number
  multipleChoiceSets?: MultipleChoiceSet[]
  approvals?: {
    uuid?: string
  }[]
  approvalFlows?: ApprovalFlow[]
  place?: {
    name?: string
    nodeId?: string
    reportId?: number
  }
  isShowScore?: boolean
  isEditable?: boolean
  isExcelConversion?: boolean
  isHideQuestionOptions?: boolean
  isAudit?: boolean
  isKeyboard?: boolean
  layoutType?: TemplateLayoutTypeEnum
  icons?: Icon[]
  improve?: Improve
  linkedIndicatedIssues?: ReportQuestionsIndicatedIssueGroup[]
  templateVersionId?: number
  hints?: HintInfo[]
}

type NewReportModeBase = {
  template: TemplateData
  reportUuid: string
  reportDate: Date
  reportAssignee: {
    uuid: string
    name: string
  }
  placeNode: {
    uuid: string
    name: string
  }
}

type ReportModeFromTemplate = NewReportModeBase & {
  modeType: 'newReportFromTemplate'
}

type ReportModeFromSchedule = NewReportModeBase & {
  modeType: 'newReportFromSchedule'
  scheduleId: number
  scheduleName: string
}

export type ReportMode =
  | ReportModeFromTemplate
  | ReportModeFromSchedule
  // 👇 editReport は以下の場合すべて共通
  // * レポート一覧からの編集
  // * スケジュールカードからの編集
  | {
      modeType: 'editReport'
      report: ReportData
    }

const getReportName = (reportMode: ReportMode): string => {
  switch (reportMode.modeType) {
    case 'editReport':
      return reportMode.report.name ?? ''
    case 'newReportFromTemplate':
      return reportMode.template.name
    case 'newReportFromSchedule':
      return reportMode.scheduleName
  }
}

const isAudit = (reportMode: ReportMode): boolean => {
  switch (reportMode.modeType) {
    case 'editReport':
      return reportMode.report.isAudit ?? false
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.template.isAudit ?? false
  }
}

const isEditable = (reportMode: ReportMode): boolean => {
  switch (reportMode.modeType) {
    case 'editReport':
      return reportMode.report.isEditable ?? false
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.template.isEditable ?? false
  }
}

const layoutType = (
  reportMode: ReportMode,
): TemplateLayoutTypeEnum | undefined => {
  switch (reportMode.modeType) {
    case 'editReport':
      return reportMode.report.layoutType
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.template.layoutType
  }
}

const getReportUuid = (reportMode: ReportMode): string => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.reportUuid
    case 'editReport':
      return reportMode.report.uuid
  }
}

const getReportId = (reportMode: ReportMode): number | undefined => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return undefined
    case 'editReport':
      return reportMode.report.id
  }
}

const getReportDate = (reportMode: ReportMode): Date | undefined => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.reportDate
    case 'editReport':
      return reportMode.report.reportDate
  }
}

type AssigneeType = Extract<
  ReportMode,
  { modeType: 'newReportFromTemplate' }
>['reportAssignee']

const getAssignee = (reportMode: ReportMode): AssigneeType | undefined => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.reportAssignee
    case 'editReport':
      return reportMode.report.assignee
  }
}

type PlaceNodeType = Extract<
  ReportMode,
  { modeType: 'newReportFromTemplate' }
>['placeNode']

const getPlaceNode = (reportMode: ReportMode): PlaceNodeType | undefined => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.placeNode
    case 'editReport':
      return reportMode.report.place?.nodeId
        ? {
            uuid: reportMode.report.place.nodeId,
            name: reportMode.report.place.name ?? '',
          }
        : undefined
  }
}

const getHintInfoList = (reportMode: ReportMode): HintInfo[] => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.template.hints
    case 'editReport':
      return reportMode.report.hints ?? []
  }
}

export const ReportMode = {
  getReportName,
  getReportUuid,
  getReportId,
  getReportDate,
  getAssignee,
  getPlaceNode,
  getHintInfoList,
  layoutType,
  isAudit,
  isEditable,
}
