import { ContentPasteOutlined } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { useTypedNavigate } from '~/app/routes/hooks'
import { routes } from '~/app/routes/paths'

export function useMenuPageLinkItems() {
  const navigate = useTypedNavigate()
  const menuPageLinkItems = [
    {
      handleNavigation: () => {
        navigate(routes.home, {})
      },
      Icon: EditIcon,
      path: routes.home.path,
      showsInOfflineMode: true,
      title: 'ホーム',
    },
    {
      handleNavigation: () => {
        navigate(routes.reports, { queryParams: undefined })
      },
      Icon: ContentPasteOutlined,
      path: routes.reports.path,
      showsInOfflineMode: false,
      title: 'レポート一覧',
    },
    {
      handleNavigation: () => {
        navigate(routes.expiredCalendar, { queryParams: undefined })
      },
      Icon: QueryBuilderIcon,
      path: routes.expiredCalendar.path,
      showsInOfflineMode: false,
      title: '期限切れカレンダー',
    },
  ]
  const menuLinkPathsAvailableInOfflineMode: string[] = menuPageLinkItems
    .filter(item => item.showsInOfflineMode)
    .map(item => item.path)

  return { menuPageLinkItems, menuLinkPathsAvailableInOfflineMode }
}
