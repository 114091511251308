import type {
  TemplateNodeSchemaLatest,
  TemplateSchemaLatest,
} from '~/adapter/indexedDB/types'
import { convertNodeWithMedia } from '~/domain/report/converters/offline/toOffline/converter'
import type { Node } from '~/domain/report/model/report/node/node'
import { safeParseBlock } from '~/lib/editorjs/parse'
import { OfflineResourceDownloadError } from './error'
import type { TemplateManual } from '@ulysses-inc/harami_api_client'

export const convertToOfflineManuals = (
  manuals?: TemplateManual[],
): TemplateSchemaLatest['data']['template']['manuals'] | Error => {
  if (!manuals) {
    return []
  }
  try {
    return manuals.map(manual => {
      const blocks = manual.blocks.map(block => {
        const result = safeParseBlock(block)
        if (result.success) {
          return result.data
        }
        throw new OfflineResourceDownloadError(
          'Failed to convert manual block',
          {
            manualName: manual.name,
            block,
          },
        )
      })

      return {
        name: manual.name,
        blocks,
        type: 'remote',
      }
    })
  } catch (e) {
    if (e instanceof Error) {
      return e
    }
    return new OfflineResourceDownloadError('Failed to convert manual blocks')
  }
}

type MediaToDownload = {
  url: string
  fileUuid: string
  templateId: number
}

export const convertToOfflineNodesWithMedia = async (
  templateId: number,
  nodes: Partial<Record<number, Node>>,
) => {
  const mediaToDownload: MediaToDownload[] = []
  const offlineNodes: TemplateNodeSchemaLatest['data']['nodes'] = {}
  for (const node of Object.values(nodes)) {
    if (!node) {
      continue
    }
    const { media, node: convertedNode } = await convertNodeWithMedia(node)
    offlineNodes[node.id] = convertedNode
    if (media) {
      mediaToDownload.push(
        ...media.map(m => ({
          url: m.url,
          fileUuid: m.uuid,
          templateId,
        })),
      )
    }
  }

  return { mediaToDownload, offlineNodes }
}
