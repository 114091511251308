import type { Updaters } from '~/adapter/indexedDB/types'
import type { ToDefined } from '~/utils/types/defined'
import type { OfflineDownloadInfoSchemaV2 } from '../../2/downloadInfo/downloadInfo'
import type { EmployeeSchemaV2 } from '../../2/employee/employee'
import type { FeatureFlagSchemaV2 } from '../../2/featureFlag/featureFlag'
import type {
  MultipleChoiceSetSchemaV2,
  TemplateMultipleChoiceSetRelationSchemaV2,
} from '../../2/multipleChoiceSet/multipleChoiceSet'
import type { ReportNodeSchemaV2, ReportSchemaV2 } from '../../2/report/report'
import type { ScheduleItemSchemaV2 } from '../../2/schedule/scheduleItem'
import type {
  TemplateNodeSchemaV2,
  TemplateSchemaV2,
} from '../../2/template/template'
import type { TemplateMediaSchemaV2 } from '../../2/template/templateMedia'
import type { VersionSchemaV2 } from '../../2/version/version'
import type { OfflineDownloadInfoSchemaV3 } from '../downloadInfo/downloadInfo'
import type { Block } from '../editorjs/type'
import type { EmployeeSchemaV3 } from '../employee/employee'
import type { FeatureFlagSchemaV3 } from '../featureFlag/featureFlag'
import type {
  MultipleChoiceSetSchemaV3,
  TemplateMultipleChoiceSetRelationSchemaV3,
} from '../multipleChoiceSet/multipleChoiceSet'
import type { MemoV3 } from '../report/node/questionNode/memo'
import type { HintV3 } from '../report/node/questionNode/question'
import type {
  ReportManualSchemaV3,
  ReportNodeSchemaV3,
  ReportSchemaV3,
} from '../report/report'
import type { ScheduleItemSchemaV3 } from '../schedule/scheduleItem'
import type {
  TemplateNodeSchemaV3,
  TemplateSchemaV3,
} from '../template/template'
import type { TemplateMediaSchemaV3 } from '../template/templateMedia'
import type { VersionSchemaV3 } from '../version/version'

const extractManuals = (reportMode: ReportSchemaV2['data']['reportMode']) => {
  switch (reportMode.modeType) {
    case 'newReportFromTemplate':
    case 'newReportFromSchedule':
      return reportMode.template.manuals
    case 'editReport':
      return reportMode.report.manuals
  }
}

type NodeV2 = ToDefined<ReportNodeSchemaV2['data']['nodes'][0]>
type QuestionNodeV2 = Extract<NodeV2, { type: 'question' }>
type QuestionNodeSchemaByType<
  T extends QuestionNodeV2['questionType'],
  K extends QuestionNodeV2 = QuestionNodeV2,
> = K extends {
  questionType: T
}
  ? K
  : never

const migrateTextMemo = (
  textMemos?: ToDefined<QuestionNodeV2['memo']>['textMemos'],
): MemoV3['textMemo'] => {
  if (!textMemos) return undefined
  const foundTextMemo = textMemos.find(({ content }) => content)
  return foundTextMemo ? { content: foundTextMemo.content } : undefined
}

const migrateQuestionNode = <T extends QuestionNodeV2['questionType']>(
  prevNode: QuestionNodeSchemaByType<T>,
) => {
  const hints = prevNode.question.hints.map(hint => {
    return {
      id: hint.id ?? 0,
      hintId: hint.hintId ?? '',
      name: hint.name ?? '',
      type: 'remote',
      blocks: hint.blocks ? (hint.blocks as Block[]) : [],
    } satisfies HintV3
  })
  const memo = {
    textMemo: migrateTextMemo(prevNode.memo?.textMemos),
    handwrittenMemos: prevNode.memo?.handwrittenMemos ?? [],
    photoMemos: prevNode.memo?.photoMemos ?? [],
  } satisfies MemoV3
  return {
    ...prevNode,
    question: {
      ...prevNode.question,
      hints,
    },
    memo,
  }
}

const migrateNode = (
  prevNode: ToDefined<ReportNodeSchemaV2['data']['nodes'][0]>,
): ToDefined<ReportNodeSchemaV3['data']['nodes'][0]> => {
  switch (prevNode.type) {
    case 'logic': {
      return prevNode
    }
    case 'question': {
      return migrateQuestionNode(prevNode)
    }
    case 'section': {
      return prevNode
    }
    case 'page': {
      return prevNode
    }
    default:
      return prevNode satisfies never
  }
}

const migrateNodes = (prevNodes: ReportNodeSchemaV2['data']['nodes']) => {
  const nodes: ReportNodeSchemaV3['data']['nodes'] = {}
  for (const node of Object.values(prevNodes)) {
    if (node) {
      nodes[node.id] = migrateNode(node)
    }
  }
  return nodes
}

const version = 3
type versionType = typeof version

export const migration: Pick<
  Updaters[versionType],
  'upgradeBefore' | 'migrations'
> = {
  upgradeBefore: async tx => {
    const reports: ReportSchemaV2[] = await tx.table('reports').toArray()
    for (const report of reports) {
      const manuals = extractManuals(report.data.reportMode)
      const newManuals: ReportManualSchemaV3['data']['manuals'] =
        manuals?.map(manual => {
          return {
            type: 'remote',
            name: manual.name,
            blocks: manual.blocks as Extract<
              ReportManualSchemaV3['data']['manuals'][0],
              { type: 'remote' }
            >['blocks'],
          }
        }) ?? []
      const reportManual: ReportManualSchemaV3 = {
        uuid: report.uuid,
        placeNodeId: report.placeNodeId,
        companyId: report.companyId,
        data: {
          manuals: newManuals,
        },
      }
      await tx.table('reportManuals').put(reportManual)
    }
  },
  migrations: {
    versions: (prev: VersionSchemaV2): VersionSchemaV3 => {
      return {
        ...prev,
        // NOTICE: version テーブルのインクリメントを忘れないこと
        version,
      }
    },
    featureFlags: (prev: FeatureFlagSchemaV2): FeatureFlagSchemaV3 => {
      return prev
    },
    reports: (prev: ReportSchemaV2): ReportSchemaV3 => {
      const migrateReportMode = (
        prevReportMode: ReportSchemaV2['data']['reportMode'],
      ) => {
        switch (prevReportMode.modeType) {
          case 'newReportFromTemplate':
          case 'newReportFromSchedule':
            return {
              ...prevReportMode,
              template: {
                ...prevReportMode.template,
                hints: prevReportMode.template.hints ?? [],
              },
            }
          case 'editReport':
            return prevReportMode
        }
      }
      const current = {
        ...prev,
        data: {
          ...prev.data,
          reportMode: migrateReportMode(prev.data.reportMode),
        },
      }
      return current
    },
    reportNodes: (prev: ReportNodeSchemaV2): ReportNodeSchemaV3 => {
      const current = {
        ...prev,
        data: {
          ...prev.data,
          nodes: migrateNodes(prev.data.nodes),
        },
      }
      return current
    },
    employees: (prev: EmployeeSchemaV2): EmployeeSchemaV3 => {
      return prev
    },
    offlineDownloadInfos: (
      prev: OfflineDownloadInfoSchemaV2,
    ): OfflineDownloadInfoSchemaV3 => {
      return prev
    },
    scheduleItems: (prev: ScheduleItemSchemaV2): ScheduleItemSchemaV3 => {
      return prev
    },
    templates: (prev: TemplateSchemaV2): TemplateSchemaV3 => {
      const manuals: TemplateSchemaV3['data']['template']['manuals'] =
        prev.data.template.manuals?.map(manual => {
          return {
            type: 'remote',
            name: manual.name,
            blocks:
              manual.blocks as TemplateSchemaV3['data']['template']['manuals'][0]['blocks'],
          }
        }) ?? []
      const current = {
        ...prev,
        data: {
          ...prev.data,
          template: {
            ...prev.data.template,
            manuals,
            hints: prev.data.template.hints ?? [],
          },
        },
      }
      return current
    },
    templateNodes: (prev: TemplateNodeSchemaV2): TemplateNodeSchemaV3 => {
      const current = {
        ...prev,
        data: {
          ...prev.data,
          nodes: migrateNodes(prev.data.nodes),
        },
      }
      return current
    },
    templateMedia: (prev: TemplateMediaSchemaV2): TemplateMediaSchemaV3 => {
      return prev
    },
    multipleChoiceSets: (
      prev: MultipleChoiceSetSchemaV2,
    ): MultipleChoiceSetSchemaV3 => {
      return prev
    },
    templateMultipleChoiceSetRelations: (
      prev: TemplateMultipleChoiceSetRelationSchemaV2,
    ): TemplateMultipleChoiceSetRelationSchemaV3 => {
      return prev
    },
  },
}
