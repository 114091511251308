import { useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import type { ApiError } from '~/adapter/api/error'
import {
  getRequestError,
  isAppVersionInconsistentError,
  isAuthError,
} from '~/adapter/api/error'
import { useLogout } from '~/domain/logout/useLogout'
import { useAppVersionConsistency } from '~/stores/appVersionConsistency/appVersionConsistency'
import { ErrorWithExtra } from '~/utils/error'
import type { DefaultOptions } from '@tanstack/react-query'

const handleApiError = async (
  logout: ReturnType<typeof useLogout>['logout'],
  inconsistentVersionDetected: () => void,
  error: { type: 'apiError' } & ApiError,
) => {
  if (isAuthError(error)) {
    Sentry.withScope(scope => {
      scope.setLevel('info')
      Sentry.captureException(
        new ErrorWithExtra(
          'AuthError',
          'queryClient enforced logout with error code:' + error.code,
          error,
        ),
      )
    })
    await logout({ userInitiated: false, errorCode: error.code })
    return
  }

  if (isAppVersionInconsistentError(error)) {
    inconsistentVersionDetected()
    return
  }

  Sentry.captureException(
    new ErrorWithExtra(
      'ServerReturnedSomeError',
      'API Server returned an error',
      error,
    ),
  )
}

const queryOptions: DefaultOptions = {
  queries: {
    // <networkMode>
    // @doc: https://tanstack.com/query/v4/docs/react/guides/network-mode#network-mode-always
    // defaultでは`online`になり、オフライン状態ではリクエストを投げずonErrorでエラーをキャッチできないので、
    // 基本的には`always`にしておく
    networkMode: 'always',
    staleTime: 0,
    gcTime: 0,
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
  },
}

export const useAppQueryClient = () => {
  const { logout } = useLogout()

  const inconsistentVersionDetected = useAppVersionConsistency(
    state => state.inconsistentVersionDetected,
  )

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          ...queryOptions,
          mutations: {
            networkMode: 'always',
            // mutationのonErrorは呼び出し側で制御するためにオーバーライド可能な作りにしたいので、
            // mutationCacheではなくdefaultOptionsにonErrorを設定
            onError: async e => {
              const error = await getRequestError(e)
              if (error.type === 'unexpectedError') {
                Sentry.captureException(
                  new ErrorWithExtra(
                    'MutateError',
                    'mutation onError handle UnexpectedError',
                    { e },
                  ),
                )
                return
              }
              await handleApiError(logout, inconsistentVersionDetected, error)
            },
          },
        },
        queryCache: new QueryCache({
          onError: async e => {
            const error = await getRequestError(e)
            // queryCacheでhandleするエラーはapiErrorのみのはずなのでそれ以外のエラーは特別なエラーを出す
            if (error.type === 'unexpectedError') {
              Sentry.captureException(
                new ErrorWithExtra(
                  'QueryCacheError',
                  'QueryCache handle UnexpectedError',
                  { e },
                ),
              )
              return
            }
            await handleApiError(logout, inconsistentVersionDetected, error)
          },
        }),
      }),
    [inconsistentVersionDetected, logout],
  )

  return { queryClient }
}
