import { useEffect, useState, type ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { getRequestError } from '~/adapter/api/error'
import gotonSrc from '~/assets/goton/goton-aseri.svg'
import logoSrc from '~/assets/logo/logo.svg'
import { PageLayout } from '~/components/layout/PageLayout/PageLayout'
import { useLogout } from '~/domain/logout/useLogout'
import {
  useAppVersionConsistency,
  selectIsAppVersionConsistent,
} from '~/stores/appVersionConsistency/appVersionConsistency'
import { useSyncedAuthState } from '../hooks/useSyncedAuthState'
import { routes } from './paths'

type Props = {
  children: ReactNode
}

type AuthCheckResult =
  | 'passed'
  | 'failed'
  | 'failed_with_past_auth_state'
  | 'service_assignment_required'
  | 'relogin_required'
  | 'unknown'

export function ProtectedRoute({ children }: Props) {
  const isAppVersionConsistent = useAppVersionConsistency(
    selectIsAppVersionConsistent,
  )
  const { isAuthed, isSyncedNow, error } = useSyncedAuthState()

  const [authCheckResult, setAuthCheckResult] =
    useState<AuthCheckResult>('unknown')
  useEffect(() => {
    const checkAuth = async () => {
      if (error) {
        const reqErr = await getRequestError(error)
        if (reqErr.type === 'unexpectedError') {
          setAuthCheckResult('failed')
          return
        }

        // ログイン状態チェックのリクエスト (`GET /loginUser/context`) のエラーハンドリング
        switch (reqErr.code) {
          case ErrorCodeEnum.ServiceAssignmentRequiredError:
            setAuthCheckResult('service_assignment_required')
            return
          case ErrorCodeEnum.InvalidAudienceError:
            setAuthCheckResult('relogin_required')
            return
          default:
            setAuthCheckResult('failed')
            return
        }
      }

      // NOTE: その場でsyncした際はerrorが返るが、syncしなかった場合は返らないのでisAuthed:falseをエラー扱いする必要がある
      if (!isSyncedNow && !isAuthed) {
        setAuthCheckResult('failed_with_past_auth_state')
        return
      }

      if (isAuthed) {
        setAuthCheckResult('passed')
        return
      }

      setAuthCheckResult('unknown')
    }

    void checkAuth()
  }, [error, isAuthed, isSyncedNow])

  switch (authCheckResult) {
    case 'unknown':
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )
    case 'failed_with_past_auth_state':
      return <AuthErrorInOfflineMode />
    case 'service_assignment_required':
    case 'relogin_required':
    case 'failed': {
      const errorCodes = {
        service_assignment_required:
          ErrorCodeEnum.ServiceAssignmentRequiredError,
        relogin_required: ErrorCodeEnum.InvalidAudienceError,
        failed: ErrorCodeEnum.AuthenticationExpired,
      }
      return (
        <Navigate
          to={routes.login.buildPath({
            queryParams: {
              // アプリのバージョンが不整合の場合、全てのAPIコールが基本的に失敗するため、
              // ログイン状態かどうかの判定自体ができない。そのため、認証切れのフィードバックは行わない
              errorCode: isAppVersionConsistent
                ? errorCodes[authCheckResult]
                : undefined,
            },
          })}
          replace
        />
      )
    }
    case 'passed':
      return children
  }
}

function AuthErrorInOfflineMode() {
  const { logout } = useLogout()

  return (
    <PageLayout header={{ component: null }} mainLayoutType="center">
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="img" src={logoSrc} sx={{ width: '100%' }} />
        <Typography variant="h1" sx={{ mt: 1 }}>
          オフラインモード中にエラーが発生しました。
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          改めてログインをしてから、再度オフラインモードに切り替えてください。
          <br />
          問題が解決しない場合は、お手数ですが担当者にお問い合わせください。
        </Typography>
        <Box component="img" src={gotonSrc} sx={{ width: '50%', mt: 3 }} />
        <Button
          onClick={async () => {
            await logout({ userInitiated: true })
          }}
          variant="contained"
          sx={{ width: '100%', mt: 2 }}
          size="large"
        >
          ログインする
        </Button>
      </Box>
    </PageLayout>
  )
}
