import type { ReportNodeSchemaLatest } from '~/adapter/indexedDB/types'
import type { ImageAnswer } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageAnswer'
import type { ImageQuestionNode } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageQuestionNode'
import { convertReportImage } from '../image/convertImage'

type ImageQuestionNodeSchema = Extract<
  ReportNodeSchemaLatest['data']['nodes'][0],
  { type: 'question'; questionType: 'resultImage' }
>
type ImageAnswerSchema = Extract<
  ReportNodeSchemaLatest['data']['nodes'][0],
  { type: 'question'; questionType: 'resultImage' }
>['answer']
type ReportImageSchema = Exclude<ImageAnswerSchema, undefined>['images'][0]

// TODO: convertResultImageQuestionNode に rename
// labels: ph.3
export const convertResultImage = async (
  node: ImageQuestionNode,
): Promise<ImageQuestionNodeSchema> => {
  const answer = await convertAnswer(node.answer)
  return {
    ...node,
    answer,
    memo: {
      handwrittenMemos: await Promise.all(
        node.memo.handwrittenMemos.map(convertReportImage),
      ),
      photoMemos: await Promise.all(
        node.memo.photoMemos.map(convertReportImage),
      ),
      textMemo: node.memo.textMemo,
    },
  }
}

const convertAnswer = async (
  answer: ImageAnswer | undefined,
): Promise<ImageAnswerSchema | undefined> => {
  if (answer === undefined) {
    return undefined
  }

  const reportImages: ReportImageSchema[] = []

  for (const image of answer.images) {
    const reportImage = await convertReportImage(image)
    reportImages.push(reportImage)
  }

  return {
    ...answer,
    images: reportImages,
  }
}
